@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
.transition-all {
  transition: all 0.3s ease-in-out;
}
.cursor {
  z-index: 999;
  background: #2696e8;
  width: 20px;
  height: 20px;
  border-radius: 50;
  pointer-events: none;
  box-shadow: 0 0 20px #2696e8, 0 0 60px #2696e8, 0 0 100px #2696e8;
  animation: colors 5s infinite;
}
.cursor::before {
  content: "";
  position: absolute;
  background: #2696e8;
  width: 50px;
  height: 50px;
  opacity: 0.2;
  transform: translate(-30%, -30%);
  border-radius: 50%;
}
* {
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
}
.hero {
  position: relative;
  overflow: hidden;
}

.blink-caret {
  display: inline-block;
  width: 10px;
  height: 20px;
  animation: blink 0.75s step-end infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
